// US 122614 - Commenting out the market map feature for removal. To enable it in the future, uncomment all occurrences.
// import { MarketMapColumns } from 'src/models/marketmap/marketmap.model';

export enum DxPivotGridDataType {
  number = 'number',
  string = 'string',
  date = 'date',
}

// TODO: As of now we are using only datatype for glide
export enum GlideDataType {
  DateTime = 'DateTime',
  Date = 'Date',
  Object = 'Object',
  ObjectCollection = 'ObjectCollection',
  String = 'String',
  LargeString = 'LargeString',
  Decimal = 'Decimal',
  Bit = 'Bit',
}

export enum PivotDataType {
  D = 'D', // data field
  T = 'T', // total row/column
  GT = 'GT', // Grand total row/column
}

export enum PivotArea {
  'C' = 'column',
  'D' = 'data',
  'F' = 'filter',
  'R' = 'row',
}

export enum PivotSummaryType {
  'Average' = 'avg',
  'Count' = 'count',
  'Custom' = 'custom',
  'Max' = 'max',
  'Min' = 'min',
  'Sum' = 'sum',
}

export type PivortSummaryDisplayMode =
  | 'absoluteVariation'
  | 'percentOfColumnGrandTotal'
  | 'percentOfColumnTotal'
  | 'percentOfGrandTotal'
  | 'percentOfRowGrandTotal'
  | 'percentOfRowTotal'
  | 'percentVariation';

export const PivotGridNewOrderFilter = 'Instrument';

export type PivotGridField = {
  allowCrossGroupCalculation?: boolean;
  allowExpandAll?: boolean;
  allowFiltering?: boolean;
  allowSorting?: boolean;
  allowSortingBySummary?: boolean;
  area?: PivotArea;
  areaIndex?: number;
  calculateCustomSummary?: (props: any) => any;
  calculateSummaryValue?: (props: any) => any;
  caption?: string;
  customizeText?: (props: any) => any;
  dataField: any;
  dataType: DxPivotGridDataType;
  displayFolder?: string;
  expanded?: boolean;
  filterType?: string;
  filterValues?: any[];
  format?: any;
  groupIndex?: number;
  groupInterval?: number | string;
  groupName?: string;
  headerFilter?: any;
  isMeasure?: boolean;
  name?: string;
  runningTotal?: 'column' | 'row';
  selector?: (data: any) => any;
  showGrandTotals?: boolean;
  showTotals?: boolean;
  showValues?: boolean;
  sortBy?: string;
  sortBySummaryField?: string;
  sortBySummaryPath?: string[] | number[];
  sortingMethod?: () => any;
  sortOrder?: string;
  summaryDisplayMode?: PivortSummaryDisplayMode;
  summaryType?: PivotSummaryType;
  visible?: boolean;
  width?: number;
  wordWrapEnabled?: boolean;
};

// TODO: Remove this hardcoded view once we implement storing and reading layout for pivotgrid
export const defaultView = [
  { display_name: 'Instrument', area: 'row', areaIndex: 1 },
  { display_name: 'Moodys industry', area: 'row', areaIndex: 2 },
  { display_name: 'Quantity', area: 'row', areaIndex: 3 },
  // US 122614 - Commenting out the market map feature for removal. To enable it in the future, uncomment all occurrences.
  // { display_name: MarketMapColumns.MarketMapLastPrice, area: 'row', areaIndex: 4 },
  { display_name: 'Position', area: 'row', areaIndex: 5 },
  { display_name: 'Direction', area: 'row', areaIndex: 6 },
  { display_name: 'Amount (Traded)', area: 'data' },
  { display_name: 'PositionAmount', area: 'data' },
  {
    display_name: 'Compliance Test Par Item Name',
    area: 'row',
    areaIndex: 0,
    sortOrder: 'desc',
    expanded: true,
    summaryType: 'custom',
  },
  { display_name: 'Display Name', area: 'row', areaIndex: 1, expanded: true, summaryType: 'custom' },
  { display_name: 'Fund', area: 'column', areaIndex: 0, sortOrder: 'desc', expanded: true, summaryType: 'custom' },
  { display_name: 'Current Test Date', area: 'column', areaIndex: 1, summaryType: 'count' },
  { display_name: 'Current Value', area: 'data', areaIndex: 0, summaryType: 'custom' },
  { display_name: 'Test Result', area: 'data', areaIndex: 2, summaryType: 'custom' },
  { display_name: 'Current Cushion', summaryType: 'count' },
  { display_name: 'Compliance Current Trigger', area: 'data', areaIndex: 1, summaryType: 'custom' },
];

export interface PivotGridState {
  area: string | undefined;
  areaIndex: number | undefined;
  expanded: boolean | undefined;
  filterType: string | undefined;
  filterValues: any[] | undefined | null;
  name: string | undefined;
  dataField: string | undefined;
  sortBy: string | undefined;
  sortBySummaryField: string | undefined;
  sortBySummaryPath: string | undefined;
  sortOrder: string | undefined;
  summaryDisplayMode: string | undefined;
  summaryType: string | undefined;
  dataType?: string;
  selector?: any;
}

export type PivotField = PivotGridState;

export type PivotLayout = {
  fields: PivotField[];
  rowExpandedPaths: any;
  columnExpandedPaths: any;
};
