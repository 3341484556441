import React from 'react';
import { CellData } from '@virtus/glide/src/components/grids/dxgrid-client-view/dxgrid-client-view.model';
import * as S from './object-type-cell-render.style';
import DOMPurify from 'dompurify';
import { GetSelection } from '../search-selection';

interface ObjectTypeUriCellProps {
  cellData: CellData;
}

export const ObjectTypeUriCellRender: React.FC<ObjectTypeUriCellProps> = ({ cellData }) => {
  const uriObj = cellData?.row?.data[cellData.column.dataField] as any;
  const cellText = uriObj?.display_name || uriObj || '',
    search = cellData.component.option('searchPanel.text');
  let selectedText;
  if (search && search.length) {
    selectedText = GetSelection(cellText, search);
  }
  const hasUriAndDisplayName =
    cellData.data[cellData.column.dataField]?.display_name && cellData.data[cellData.column.dataField]?.uri;
  const textToDisplay = selectedText ? DOMPurify.sanitize(selectedText) : DOMPurify.sanitize(cellText);
  if (typeof cellText === 'string' && cellText && !hasUriAndDisplayName) {
    return (
      <React.Fragment>
        <div dangerouslySetInnerHTML={{ __html: textToDisplay }}></div>
      </React.Fragment>
    );
  } else if (hasUriAndDisplayName) {
    return (
      <React.Fragment>
        <S.ObjectTypeUriCell dangerouslySetInnerHTML={{ __html: textToDisplay }}></S.ObjectTypeUriCell>
      </React.Fragment>
    );
  } else return cellData?.data[cellData.column.dataField];
};
