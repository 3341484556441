import { actionTypes, MutateSuccessAction, requestAsync } from 'redux-query';
import { put, putResolve, select, takeLatest } from 'redux-saga/effects';
import { actions } from 'src/reducers/actions';
import {
  GlideNotificationTypes,
  MUTATE_START_NOTIFICATION,
  MUTATE_SUCCESS_NOTIFICATION,
  NOTIF_ACTION,
  NotificationsAction,
  ReduxQueryActionType,
} from 'src/reducers/notifications';
import { ActionResult } from 'src/utils/action-resolver';
import { glideQuery, glideQuerySelector } from 'src/api/query';
import { selectCVC } from 'src/reducers/tabs';
import { isEmpty } from 'lodash';
import { ClientViewConfigurationData } from 'src/components/glide-view/glide-view.model';
import { VIEW_INSPECTOR } from 'src/components/inspectors/glide-object-inspector/view-inspector';
import {
  MODAL_INSPECTOR,
  selectModalInspectorData,
} from 'src/components/inspectors/glide-object-inspector/modal-inspector';
import { parseDisplayViewData } from 'src/api/queries/display-view';
import {
  selectCurrentObjectInViewList,
  updateComponentAction,
  updateComponentViewAction,
} from 'src/reducers/components';
import { setModalForm } from 'src/reducers/modalForm.reducer';

interface ActionResolved {
  payload: ActionResult;
}

export function* mutateStart(action: any) {
  // Custom notification
  if (action?.meta?.notification && action.meta.notification.hasOwnProperty(actionTypes.MUTATE_START)) {
    yield put({
      type: NotificationsAction.MUTATE_START,
      payload: { title: action.meta.notification[actionTypes.MUTATE_START] },
    });
  } else {
    // Generic notification
    yield put({
      type: NotificationsAction.MUTATE_START,
      payload: MUTATE_START_NOTIFICATION(),
    });
  }
}

/**
 * Update the GOM dataGridContent, client view grid and GOI when GOM datagrid content is updated
 * @param action
 */
export function* refreshRelatedObjects(action: any, queryKey: string): any {
  // Update the GOM
  const { object_uri } = action.body;
  const { object_field_name } = action.meta;
  yield putResolve(
    requestAsync(
      glideQuery({
        endpoint: '/glide/object',
        body: { object_uri, object_field_name },
        queryKey: queryKey,
      }),
    ),
  );

  // TODO: Remove hack, use saga-test-plan to provide value
  const cvc: ClientViewConfigurationData = process?.env?.JEST_WORKER_ID
    ? { uri: 'cvc_test_uri' }
    : yield select(selectCVC);
  // keepig it for future referance
  // yield putResolve(
  //   requestAsync(
  //     glideQuery({
  //       endpoint: '/glide/view',
  //       body: { uri: cvc.uri },
  //     }),
  //   ),
  // );

  // Update the GOI
  // TODO: Remove hack, use saga-test-plan to provide value
  const inspectorData = process?.env?.JEST_WORKER_ID
    ? { uri: 'inspector_uri' }
    : yield select(glideQuerySelector, cvc.uri, VIEW_INSPECTOR);
  if (inspectorData?.uri) {
    yield putResolve(
      requestAsync(
        glideQuery({
          endpoint: '/glide/display-view/groups',
          body: {
            uri: inspectorData.uri,
            fetch_options: 'workflow_transitions',
            expand_prop: 'actions',
          },
          storeViewName: cvc.uri,
          storeViewProp: VIEW_INSPECTOR,
          queryKey: 'GOI',
        }),
      ),
    );
  }
}

export function* mutateSuccess(action: MutateSuccessAction) {
  // Custom notification
  if (
    // Dispatched for collection using static actions (edge case for grid without a consistent schema like hypo scenario order)
    action.queryKey === 'deleteObjectInCollection' ||
    // Generic update dispatched by DX batch edit (most GOM datagrid)
    // Only dispatched if rows have changed (deleted) or added
    ((action.queryKey?.includes('gom') || action.queryKey === 'orderBottomPanelGrid') &&
      !(isEmpty(action.meta?.changedRows) && isEmpty(action.meta?.newRows)))
  ) {
    yield refreshRelatedObjects(action, action.queryKey);
  }
  if (action.meta?.notification && action.meta.notification.hasOwnProperty(actionTypes.MUTATE_SUCCESS)) {
    yield put({
      type: NotificationsAction.MUTATE_SUCCESS,
      payload: { title: action.meta.notification[actionTypes.MUTATE_SUCCESS] },
    });
  } else {
    // Generic notification
    yield put({
      type: NotificationsAction.MUTATE_SUCCESS,
      payload: MUTATE_SUCCESS_NOTIFICATION(),
    });
  }
}
export function* requestFailure(action: any) {
  yield put({
    type: NotificationsAction.ERROR_NOTIFICATION,
    payload: { errorMessage: action.responseBody?.error_message },
  });
}
// Generate notification as an action of GlideNotificationTypes
export function* actionResolved(action: ActionResolved | any): any {
  const glideNotificationType = GlideNotificationTypes[action?.payload?.resolved_entity_type as GlideNotificationTypes];

  if (action?.payload?.data?.action_uri === 'instance/actions/delete_object') {
    const clientViewConfiguration = yield select(selectCVC);
    yield putResolve(
      requestAsync(
        glideQuery({
          endpoint: '/glide/view',
          body: {
            uri: clientViewConfiguration.uri,
          },
        }),
      ),
    );

    // TODO for GOM refresh: check global flag about GOM being opened: https://virtusllc.visualstudio.com/AlphaKinetic/_workitems/edit/96697
  }

  if (!glideNotificationType) {
    yield put({
      type: NOTIF_ACTION(GlideNotificationTypes.action_resolved),
      payload: action.payload,
    });
  } else {
    yield put({
      type: NOTIF_ACTION(glideNotificationType),
      payload: action.payload,
    });
  }
}

export function* openInspector(action: ActionResolved | any): any {
  yield putResolve(
    requestAsync(
      glideQuery({
        endpoint: '/glide/display-view/groups',
        body: {
          uri: action.payload?.resolved_entity_uri,
          fetch_options: 'workflow_transitions',
          expand_prop: 'actions',
        },
        queryKey: `modal_link_${action.payload?.resolved_entity_uri}`,
        transform: (body: any) => ({ [MODAL_INSPECTOR]: parseDisplayViewData(body) }),
        update: {
          [MODAL_INSPECTOR]: (prev: any, next: any) => ({
            ...prev,
            [action.payload?.resolved_entity_uri]: {
              ...next,
            },
          }),
        },
      }),
    ),
  );
  const { resolved_entity_uri } = action.payload;
  const clientViewConfiguration: ClientViewConfigurationData = yield select(selectCVC);
  const currentObjectInView: String[] = yield select(selectCurrentObjectInViewList);
  currentObjectInView.push(resolved_entity_uri);
  yield put(
    updateComponentViewAction('currentObjectInView', clientViewConfiguration.uri, { uri: currentObjectInView }),
  );
  const modalInspectorData = yield select(selectModalInspectorData);
  yield put(setModalForm(modalInspectorData[action.payload?.resolved_entity_uri]));
  yield put(updateComponentAction(MODAL_INSPECTOR, { [resolved_entity_uri]: { isVisible: true } }));
}

export function* clearNotifications() {
  yield put({
    type: actions.errors.RESET,
  });
}

function* watchClearNotification() {
  yield takeLatest(actions.notifications.RESET, clearNotifications);
}

function* watchRequestStart() {
  yield takeLatest(ReduxQueryActionType.MUTATE_START, mutateStart);
}

function* watchMutateSuccess() {
  yield takeLatest(ReduxQueryActionType.MUTATE_SUCCESS, mutateSuccess);
}

function* watchRequestFailure() {
  yield takeLatest([ReduxQueryActionType.REQUEST_FAILURE, ReduxQueryActionType.MUTATE_FAILURE], requestFailure);
}

function* watchActionResolved() {
  yield takeLatest(NotificationsAction.ACTION_RESOLVED_NOTIFICATION, actionResolved);
}

function* watchOpenInspector() {
  yield takeLatest(NotificationsAction.OPEN_INSPECTOR, openInspector);
}

export default [
  watchClearNotification,
  watchRequestStart,
  watchRequestFailure,
  watchMutateSuccess,
  watchActionResolved,
  watchOpenInspector,
];
