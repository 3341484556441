import * as signalr from '@aspnet/signalr';
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import isEmpty from 'lodash/isEmpty';
import { getAccessToken } from 'src/auth';
import loadMockData, { MockRoute } from '../utils/loadMockData';
import { getToken } from 'src/auth/sagas/IDPLoginJWT';

export const DELETE = 'DELETE';
export const GET = 'GET';
export const HEAD = 'HEAD';
export const POST = 'POST';
export const PUT = 'PUT';
export const PATCH = 'PATCH';

export interface CoreServicesClientConfig {
  baseURL: string;
  signalrUrl?: string;
  signalrApiUrl?: string;
  mockRoutes?: MockRoute;
  onUnauthorized: () => void;
  dataEnvironment?: string;
  sessionTimeOut: number;
  useIDP?: boolean;
}

interface PushMessageResponse {
  pushMessage: any;
  payloadFileName: string;
  payloadUid: string;
  payloadUrl: string;
  isPushPayload: boolean;
  isPayloadAttached: boolean;
  containerName: string;
  blobFileName: string;
}

let cSClient: CoreServicesClient;

/**
 * Client for making requests to core service based APIs.
 * Request method can toggled between traditional REST style responses
 * or server push messages using SignalR.
 */
export class CoreServicesClient {
  private _axios: AxiosInstance;
  private _signalrClientId?: string;
  private _transportType?: string;
  private _elapsedTime?: string;
  private _signalrConnection?: signalR.HubConnection;
  private _pendingSignalrInit?: Promise<void>;
  private _pendingSignalrRequestCallbacks: { [correlationId: string]: (res: PushMessageResponse) => void } = {};
  private generateSignalrUrl?: () => Promise<string>;
  private _signalrApiUrl?: string;
  private _dataEnvironment?: string;
  private get signalrIsSupported(): boolean {
    return !!this.generateSignalrUrl;
  }

  /**
   *
   * @param baseURL Base URL for all API requests
   * @param getAccessToken async Function for retrieving API access token
   * @param [signalrUrl] URL for signalr hub
   * @param [signalrApiUrl] URL for signalr api url
   * @param mockRoutes Enables mock mode, Object which contains mocked routes to be processed by #loadMockData()
   */
  constructor({
    baseURL,
    signalrUrl,
    signalrApiUrl,
    mockRoutes,
    dataEnvironment: environment,
    useIDP,
  }: CoreServicesClientConfig) {
    this._axios = axios.create({
      baseURL,
    });

    this._signalrApiUrl = signalrApiUrl;
    this._dataEnvironment = environment;
    // this loads Mocked endpoints via Mock Axios Adapter
    if (mockRoutes) loadMockData(this._axios, mockRoutes);
    // set up a request interceptor to set the auth token on each out going request
    else {
      this._axios.interceptors.request.use(async (config: AxiosRequestConfig) => {
        // TODO: handle invalid token (immediate logout?)
        const token = useIDP ? getToken() : await getAccessToken();
        config.headers.Authorization = `Bearer ${token}`;
        return config;
      });
    }

    if (signalrUrl) {
      this.generateSignalrUrl = async () => {
        const token = useIDP ? getToken() : await getAccessToken();
        // TODO: Probably need a callback for no invalid auth tokens to trigger a log out
        if (!token) throw new Error('No access token for SignalR');

        return `${signalrUrl}?access_token=${token}`;
      };
    }
  }

  /**
   * Make a request to core services.
   * This method directly accepts any Axios config parameters for more advanced use cases.
   * @param config Axios request config object
   * @param [useSignalr=false] Flag to use signalr based request/response flow,
   *                           best for very long running request. Defaults to false.
   * @returns A promise for the Axios request
   */
  public async request(config: AxiosRequestConfig = {}, useSignalr: boolean = false): Promise<any> {
    const usingSignalr = this.signalrIsSupported && ((config.headers && config.headers['x-usesignalr']) || useSignalr);

    // check if signalr is running, if not connect it
    if (usingSignalr && !this._signalrConnection) {
      if (!this._pendingSignalrInit) this._pendingSignalrInit = this.connectToSignalR();
      await this._pendingSignalrInit;
    }

    const headers = {
      ...config.headers,
    };

    if (usingSignalr && this._signalrClientId) {
      headers['x-signalrclientid'] = this._signalrClientId;
      headers['x-useSignalr'] = true;
    }

    if (this._dataEnvironment) {
      headers['x-dataenvironment'] = this._dataEnvironment;
    }

    const requestPromise = this._axios({ ...config, headers });

    return usingSignalr ? requestPromise.then(this.handleSignalrResponse) : requestPromise;
  }

  /**
   * Make a GET request to core services
   * @param url Target url, relative to the base URL
   * @param params Query params to append to the request
   * @param [useSignalr=false] Flag to use signalr based request/response flow, best for very long running request.
   */
  public get(url: string, params = {}, useSignalr?: boolean, headers?: any) {
    return this.request({ method: GET, url, params, headers }, useSignalr);
  }

  /**
   * Make a HEAD request to core services
   * @param url Target url, relative to the base URL
   * @param params Query params to append to the request
   * @param [useSignalr=false] Flag to use signalr based request/response flow, best for very long running request.
   */
  public head(url: string, params = {}, useSignalr?: boolean, headers?: any) {
    return this.request({ method: HEAD, url, params, headers }, useSignalr);
  }

  /**
   * Make a DELETE request to core services
   * @param url Target url, relative to the base URL
   * @param params Query params to append to the request
   * @param [useSignalr=false] Flag to use signalr based request/response flow, best for very long running request.
   */
  public delete(url: string, params = {}, useSignalr?: boolean, headers?: any) {
    return this.request({ method: DELETE, url, params, headers }, useSignalr);
  }

  /**
   * Make a POST request to core services
   * @param url Target url, relative to the base URL
   * @param data Data to send in the request body
   * @param [useSignalr=false] Flag to use signalr based request/response flow, best for very long running request.
   */
  public post(url: string, data: any, useSignalr?: boolean, headers?: any) {
    return this.request({ method: POST, url, data, headers }, useSignalr);
  }

  /**
   * Make a PUT request to core services
   * @param url Target url, relative to the base URL
   * @param data Data to send in the request body
   * @param [useSignalr=false] Flag to use signalr based request/response flow, best for very long running request.
   */
  public put(url: string, data: any, useSignalr?: boolean, headers?: any) {
    return this.request({ method: PUT, url, data, headers }, useSignalr);
  }

  /**
   * Make a PATCH request to core services
   * @param url Target url, relative to the base URL
   * @param data Data to send in the request body
   * @param [useSignalr=false] Flag to use signalr based request/response flow, best for very long running request.
   */
  public patch(url: string, data: any, useSignalr?: boolean, headers?: any) {
    return this.request({ method: PATCH, url, data, headers }, useSignalr);
  }

  public async connectToSignalR(): Promise<void> {
    // already have a working signalr connection
    if (this._signalrConnection) return Promise.resolve();

    if (!this.generateSignalrUrl) throw new Error('Attempted to initialize SignalR with missing configuration');

    const signalrUrl = await this.generateSignalrUrl();
    const transport = signalr.HttpTransportType.WebSockets;
    const elapsedStart = new Date().getTime();
    const signalrConnection = new signalr.HubConnectionBuilder()
      .withUrl(signalrUrl, transport)
      .configureLogging(signalr.LogLevel.Information)
      .build();

    signalrConnection.on('SendResponse', this.onSignalrSendResponse);

    signalrConnection.onclose = this.onSignalrConnectionClose;

    const clientIdPromise = new Promise<void>(resolve => {
      signalrConnection.on('GetSignalRConnectionUniqueId', clientId => {
        console.info('SignalR connection id: ', clientId);
        let elapsedEnd: number = 0;
        elapsedEnd = new Date().getTime();
        const seconds: number = ((elapsedEnd - elapsedStart) % (1000 * 60)) / 1000;
        this._signalrClientId = clientId;
        this._signalrConnection = signalrConnection;
        this._transportType = signalr.HttpTransportType[transport];
        this._elapsedTime = `${seconds} S`;
        resolve();
        this._pendingSignalrInit = undefined;
      });
    });

    await signalrConnection.start();
    signalrConnection.invoke('SetConnection', '');
    return clientIdPromise;
  }

  /**
   * Get signalR detail like signalr client id, transport type and elapsed time
   */
  public getSignalRDetail() {
    return {
      SignalrClientId: this._signalrClientId,
      TransportType: this._transportType,
      ElapsedTime: this._elapsedTime,
    };
  }

  /**
   * Disconnect the signaR connection from signalR hub
   */
  public async disconnectSignalR() {
    if (this._signalrConnection !== null && this._signalrConnection !== undefined) {
      await this._signalrConnection.stop();
      this._signalrConnection = undefined;
      this._signalrClientId = '';
      this._transportType = '';
      this._elapsedTime = '';
    }
  }

  /**
   * The flow for handle API requests set to receive responses via a signalr push message
   * @param response API response with correlation id of the push message to wait for
   */
  private handleSignalrResponse = async (response: AxiosResponse) => {
    try {
      const correlationId: string = response.data.CorrelationId;

      // Wait for message to come back from signalr for this correlation id
      const { payloadUid, containerName, blobFileName } = await this.subscribeToPushMessage(correlationId);

      const fileDownloadUrl = `${this._signalrApiUrl}/FileDownload/${containerName}/${blobFileName}`;
      // Go get the file payload with the actual data we want
      const payloadRes = await axios.get(fileDownloadUrl);

      // Extract the data from the file payload and parse it as needed
      const payload = payloadRes.data;

      const emptyPayload = isEmpty(payload);

      if (response.config.method === GET && emptyPayload) {
        throw new Error('Payload was unexpectedly empty');
      }

      // Tell blob store to delete this payload from server
      this.deletePayloadBlob(payloadUid);

      return payloadRes;
    } catch (e: any) {
      if (e.response) {
        const payloadRes: AxiosResponse = e.response;
        console.error(`Unable to fetch payload. Server returned ${payloadRes.status} - ${payloadRes.statusText}`);
      }
      const errMsg = `Request to core services for ${response.config.url} failed with error: ${e}`;
      throw new Error(errMsg);
    }
  };

  /**
   * Handler for push messages received from signalr connection
   * @param notificationMessage
   * @param isPayloadAttached
   * @param isPushPayload
   * @param payloadUrl
   * @param payloadFileName
   * @param payloadUid
   */
  private onSignalrSendResponse = (
    notificationMessage: string,
    isPayloadAttached: boolean,
    isPushPayload: boolean,
    payloadUrl: string,
    payloadFileName: string,
    payloadUid: string,
    containerName: string,
    blobFileName: string,
  ) => {
    const receivedMessage = {
      pushMessage: JSON.parse(notificationMessage),
      payloadFileName,
      payloadUid,
      payloadUrl,
      isPushPayload,
      isPayloadAttached,
      containerName,
      blobFileName,
    };
    const correlationId: string = receivedMessage.pushMessage.CorrelationId;
    const cb = this._pendingSignalrRequestCallbacks[correlationId];

    if (!cb) {
      console.error(`Received a message with an unknown or unexpected correlation id: ${correlationId}`);
      return;
    }

    delete this._pendingSignalrRequestCallbacks[correlationId];

    cb(receivedMessage);
  };

  /**
   * Handler for signalr connection disconnects
   * @param [err] optional error message associated with the disconnect
   */
  private onSignalrConnectionClose(err?: any) {
    console.info(`Signalr connection ${this._signalrClientId} disconnected`);
    if (err) {
      console.error(`Disconnected with the following error ${err}`);
    }
    this.disconnectSignalR();
  }

  /**
   * Returns a promise that resolves once the message with the desired correlation id arrives
   * @param correlationId Message id to wait on
   */
  private async subscribeToPushMessage(correlationId: string): Promise<PushMessageResponse> {
    if (!this._signalrConnection) {
      throw new Error('Attempted to subscribe to push message before signalr connection initialized');
    }
    return new Promise<PushMessageResponse>(resolve => {
      this._pendingSignalrRequestCallbacks[correlationId] = resolve;
    });
  }

  /**
   * Signal to server to delete the payload blob. Does not wait on completion.
   */
  private deletePayloadBlob(payloadUid: string) {
    if (!this._signalrConnection) throw new Error('Attempted to delete payload before signalr connection initialized');
    this._signalrConnection.invoke('DeleteBlobFromBlobStorage', payloadUid, this._signalrClientId);
  }
}

// Enforce class usage as a singleton
export default function getCSClient(config: CoreServicesClientConfig) {
  if (cSClient) {
    return cSClient;
  }
  cSClient = new CoreServicesClient({
    baseURL: config.baseURL,
    signalrUrl: config.signalrUrl,
    signalrApiUrl: config.signalrApiUrl,
    mockRoutes: config.mockRoutes,
    onUnauthorized: config.onUnauthorized,
    sessionTimeOut: config.sessionTimeOut,
    dataEnvironment: config.dataEnvironment,
  });
  return cSClient;
}
