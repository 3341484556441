import { authSelectors } from '@virtus/common/auth/reducer';
import { requestAsync } from 'redux-query';
import { put, putResolve, select, takeLatest, PutEffect } from 'redux-saga/effects';
import { parseDisplayViewData } from 'src/api/queries/display-view';
import { glideQuery } from 'src/api/query';
import {
  MODAL_INSPECTOR,
  selectModalInspectorData,
} from 'src/components/inspectors/glide-object-inspector/modal-inspector';
import {
  ComponentPropsType,
  selectComponents,
  selectCurrentObjectInViewList,
  selectStaticComponent,
  updateComponentAction,
  updateComponentViewAction,
} from 'src/reducers/components';
import { resetModalForm, setModalForm } from 'src/reducers/modalForm.reducer';
import { NotificationsAction } from 'src/reducers/notifications';
import { VALIDATION_ERROR_MSG } from 'src/utils/constants';

export enum GlideViewActions {
  INIT_MODAL_INSPECTOR = 'INIT_MODAL_INSPECTOR',
  AUDIT_TRAIL = 'AUDIT_TRAIL',
  UNLOCK_INSPECTOR = 'UNLOCK_INSPECTOR',
  VALIDATE_ORDER_EXECUTION = 'VALIDATE_ORDER_EXECUTION',
}

export function* onClickGlobalModal(action: any): any {
  const { instanceUri, cvcUri } = action.payload;
  const modalInspector = yield select(selectStaticComponent(MODAL_INSPECTOR));
  if (
    modalInspector &&
    Object.keys(modalInspector).filter((item: any) => item === instanceUri && modalInspector[item]?.isVisible).length >
      0
  ) {
    yield put({
      type: NotificationsAction.VALIDATION_ERROR_NOTIFICATION,
      payload: {
        errorMessage: 'The modal inspector is already available in the background  !!!',
      },
    });
  } else {
    yield put(resetModalForm(instanceUri));
    yield putResolve(
      requestAsync(
        glideQuery({
          endpoint: '/glide/display-view/groups',
          body: {
            uri: instanceUri,
            fetch_options: 'workflow_transitions',
            expand_prop: 'actions',
          },
          queryKey: `modal_link_${instanceUri}`,
          transform: (body: any) => ({ [MODAL_INSPECTOR]: parseDisplayViewData(body) }),
          update: {
            [MODAL_INSPECTOR]: (prev: any, next: any) => ({
              ...prev,
              [instanceUri]: {
                ...next,
              },
            }),
          },
        }),
      ),
    );

    const currentObjectInView: String[] = yield select(selectCurrentObjectInViewList);
    currentObjectInView.push(instanceUri);
    yield put(updateComponentViewAction('currentObjectInView', cvcUri, { uri: currentObjectInView }));
    const modalInspectorData = yield select(selectModalInspectorData);
    yield put(setModalForm(modalInspectorData[instanceUri]));
    if (
      modalInspectorData?.[instanceUri]?.data &&
      typeof modalInspectorData?.[instanceUri]?.data === 'object' &&
      (Object.keys(modalInspectorData?.[instanceUri]?.data).length > 0 ||
        modalInspectorData?.[instanceUri]?.data.length > 0)
    ) {
      yield put(updateComponentAction(MODAL_INSPECTOR, { [instanceUri]: { isVisible: true } }));
    } else {
      yield put({
        type: NotificationsAction.VALIDATION_ERROR_NOTIFICATION,
        payload: {
          errorMessage: `The ${instanceUri} doesn't have any data !!!`,
        },
      });
    }
  }
}

export function* onInspectorRelease(queryKey: string = 'RcordLockStatus'): any {
  const components: ComponentPropsType = yield select(selectComponents);
  const checkTargetUri = components.actionForm?.target_uri || localStorage.getItem('targetUri');

  if (checkTargetUri) {
    const getResponse = yield putResolve(
      requestAsync(
        glideQuery({
          endpoint: '/glide/gget',
          options: { method: 'GET' },
          body: { uri: checkTargetUri },
          queryKey: queryKey,
        }),
      ),
    );
    yield put(
      updateComponentAction('isRestricted', {
        restricted: getResponse?.body[0]?.data?.is_restricted || '',
      }),
    );
    // this keyActions is for locking inspector while editing.
    const keyAction = 'canceledit';
    const date = new Date().toJSON();
    const checkStatus = getResponse?.body[0]?.data?.status;
    const glideSession = yield select(authSelectors.glideSession);
    if (checkStatus?.includes('Locked for editing by ' + glideSession.username)) {
      yield putResolve(
        requestAsync(
          glideQuery({
            endpoint: '/gset',
            options: { method: 'POST' },
            body: { uri: checkTargetUri, action: keyAction, date: date },
          }),
        ),
      );
      localStorage.setItem('targetUri', '');
    }
    yield put(
      updateComponentAction('recordLocked', {
        isLocked: false,
      }),
    );
  }
}

export function* onAuditClick(): any {
  const components: ComponentPropsType = yield select(selectComponents);
  const instanceUri = components.actionForm?.target_uri;

  if (instanceUri) {
    const prod = yield putResolve(
      requestAsync(
        glideQuery({
          endpoint: '/gaudit',
          options: { method: 'GET' },
          body: { uri: instanceUri },
          queryKey: 'audit_trail',
        }),
      ),
    );
    yield put(
      updateComponentAction('auditTrail', {
        data: JSON.parse(prod.body.data),
        schema: prod.body.schema,
        visible: true,
        title: prod.body.display_name,
      }),
    );
  }
}

export function* onOrderExecution(action: any): Generator<PutEffect, void, any> {
  let isValid = true;
  const entered_committed_notional = Number(
    action.payload?.action?.['instance/argument/add_counterparty_allocation_committed_notional'].replace(/,/g, ''),
  );
  if (!isNaN(entered_committed_notional)) {
    const getResponse = yield putResolve(
      requestAsync(
        glideQuery({
          endpoint: '/glide/gget',
          options: { method: 'GET' },
          body: { uri: action.payload?.target_uri },
          queryKey: 'validateOrderExecution',
        }),
      ),
    );
    const unfilled_notional = Number(getResponse.body?.[0]?.data?.unfilled_notional);
    if (!isNaN(unfilled_notional)) {
      if (unfilled_notional - entered_committed_notional < 0) {
        yield put({
          type: NotificationsAction.VALIDATION_ERROR_NOTIFICATION,
          payload: {
            errorMessage: VALIDATION_ERROR_MSG.ORDER_EXECUTION_ERROR_MSG,
          },
        });
        isValid = false;
      }
    }
  } else {
    yield put({
      type: NotificationsAction.VALIDATION_ERROR_NOTIFICATION,
      payload: {
        errorMessage: VALIDATION_ERROR_MSG.COMMITTED_NOTIONAL_INVALID_VALUE,
      },
    });
    action.payload.reject(false);
  }
  action.payload.resolve(isValid);
}

export const globalModalClick = (payloadArguments: any) => ({
  type: GlideViewActions.INIT_MODAL_INSPECTOR,
  payload: payloadArguments,
});
export const auditClick = () => ({
  type: GlideViewActions.AUDIT_TRAIL,
});
export function* watchModalCellClick() {
  yield takeLatest<any>(GlideViewActions.INIT_MODAL_INSPECTOR, onClickGlobalModal);
}
export function* watchAuditClick() {
  yield takeLatest<any>(GlideViewActions.AUDIT_TRAIL, onAuditClick);
}
export function* watchInspectorRelease() {
  yield takeLatest<any>(GlideViewActions.UNLOCK_INSPECTOR, onInspectorRelease);
}

export function* watchValidateOrderExecution() {
  yield takeLatest<any>(GlideViewActions.VALIDATE_ORDER_EXECUTION, onOrderExecution);
}

export default [watchModalCellClick, watchAuditClick, watchInspectorRelease, watchValidateOrderExecution];
