import { call, put, takeLatest } from 'redux-saga/effects';
import { authActions, IDPConfig } from 'src/auth';
import { initIDPLogin, loginRedirect } from '../idpWrapper';
import * as queryString from 'query-string';
import { getType } from 'typesafe-actions';
import mockUser from 'src/utils/mockUser';
import { PayloadAction } from 'typesafe-actions/dist/types';

const RETURN_URL_KEY = 'RETURN_URL';

function* initialize(config: IDPConfig) {
  try {
    yield call(initIDPLogin, config);
    const deviceCode = queryString.parse(location.search);
    if (deviceCode && deviceCode.code) {
      // these are temporary constants for user and isValidUser
      // these will be replaced by the actual user and isValidUser
      const user = {};
      const isValidUser = {};
      const code = deviceCode.code;
      yield put(authActions.loginSuccess({ user, code, isValidUser }));

      // return to the requested url before login redirect (if set)
      const returnUrl = sessionStorage.getItem(RETURN_URL_KEY);
      if (returnUrl && returnUrl.length > 0) {
        history.replaceState(null, document.title, returnUrl);
      } else {
        history.replaceState(null, document.title, '/');
      }
    }
  } catch (e: any) {
    console.error(`Error in authorize flow`, e);
    yield put(authActions.loginError(e));
  } finally {
    sessionStorage.removeItem(RETURN_URL_KEY);
  }
}

function* authInit(initializeAction: PayloadAction<string, any>) {
  if (__USE_MOCK_DATA__) {
    yield put(
      authActions.loginSuccess({ user: mockUser, token: '123', isValidUser: true, glide: { token: 'glideToken-123' } }),
    );
  } else {
    yield call(initialize, initializeAction.payload);
  }

  yield put(authActions.initialized());
}

function* idpLogin() {
  if (__USE_MOCK_DATA__) {
    yield put(
      authActions.loginSuccess({ user: mockUser, token: '123', isValidUser: true, glide: { token: 'glideToken-123' } }),
    );
  } else {
    sessionStorage.setItem(RETURN_URL_KEY, location.href);
    loginRedirect();
  }
}

function* watchAuthInitialize() {
  if (typeof Cypress !== 'undefined') {
    const auth = Cypress.env('auth');
    yield put(authActions.initialized());
    yield put(authActions.loginSuccess({ user: mockUser, token: auth.access_token }));
    return auth.access_token;
  }
  yield takeLatest(getType(authActions.initialize), authInit);
}

function* watchIDPLogin() {
  yield takeLatest(getType(authActions.login), idpLogin);
}

export default [watchAuthInitialize, watchIDPLogin];
