import React from 'react';
import * as S from './objectcollection.style';
import { CellData } from 'src/components/grids/dxgrid-client-view/dxgrid-client-view.model';
interface ObjectCollectionCellRenderProps {
  cellData: CellData;
  onClick: (cellData: CellData) => void;
  renderValue?: string;
  isLinkEnabled?: boolean;
}

export const ObjectCollectionCellRender = ({
  cellData,
  onClick,
  renderValue,
  isLinkEnabled,
}: ObjectCollectionCellRenderProps) => {
  const value = Number(renderValue ?? (cellData.value as string[])?.length ?? 0);
  const handleOnClick = () => {
    onClick(cellData);
  };

  if (isLinkEnabled && value == 0) {
    return <>{value}</>;
  } else if (cellData?.column?.caption == 'Lookups' && !isLinkEnabled && value >= 0) {
    return <>{value}</>;
  } else {
    return (
      <S.ObjectCollectionCount aria-label="cell-render-link" data-testid="cell-render-link" onClick={handleOnClick}>
        {value}
      </S.ObjectCollectionCount>
    );
  }
};
