import { Done, Edit } from '@mui/icons-material';
import React, { useState } from 'react';
import { ConfirmInputButton } from '@virtus/components/Buttons';
import DropdownMenu from '@virtus/components/DropdownMenu';
import { GlideLayoutData } from 'src/components/grids/dxgrid-client-view/templates/Layouts/Layouts.model';
import { StyledInput } from 'src/components/forms/form-elements/FormElements.style';
import * as S from './layout-row.style';

interface LayoutRowProps {
  layout: GlideLayoutData;
  onRename: (layout: GlideLayoutData, newName: string) => void;
  dataTestId?: string;
  icon?: React.ReactElement;
  moreMenuOptions?: MenuOption[];
  children?: React.ReactElement;
  style?: React.CSSProperties;
  active?: boolean;
  title?: string;
}

export interface MenuOption {
  onClick: (layout: GlideLayoutData) => void;
  text: string;
  disabled?: boolean;
  icon?: React.ReactElement;
}

const iconStyle = { color: 'var(--foreground-text)', width: '16px', height: '16px' };
const dropdownMenuStyles = {
  menu: {
    paper: {
      border: 'var(--foreground-form-border)',
      backgroundColor: 'var(--background-dropdown-layout)',
    },
  },
};

const renderLayoutOptions = (moreMenuOptions: MenuOption[], layout: GlideLayoutData) =>
  moreMenuOptions.map(item => (
    <S.LayoutDropdownMenuItem data-testid="moreMenu-item" key={item.text} onClick={() => item.onClick(layout)}>
      {item.icon}
      <S.LayoutDropdownMenuItemText disabled={item.disabled}>{item.text}</S.LayoutDropdownMenuItemText>
    </S.LayoutDropdownMenuItem>
  ));

export const LayoutRow = ({
  layout,
  dataTestId,
  onRename,
  icon,
  moreMenuOptions,
  children,
  ...props
}: LayoutRowProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [newLayoutName, setNewLayoutName] = useState(layout.data.name);

  const onKeyDown = (event: React.KeyboardEvent) => {
    event.stopPropagation();
    if (event.key === 'Enter') {
      setIsEditing(false);
      onRename(layout, newLayoutName);
      setNewLayoutName('');
    }

    if (event.key === 'Escape') {
      setIsEditing(false);
      setNewLayoutName(layout.data.name);
    }
  };

  return (
    <S.LayoutRowStyled data-testid={dataTestId || 'layout-row'} {...props}>
      <S.Left>
        {!props.active ? (
          <S.LayoutEditBtn
            data-testid="edit-btn"
            onClick={() => {
              if (isEditing) {
                setNewLayoutName(layout.data.name);
              }
              setIsEditing(!isEditing);
            }}
          >
            {icon || <Edit style={{ ...iconStyle, opacity: !props.active ? 1 : 0.2 }} />}
          </S.LayoutEditBtn>
        ) : null}
        {!isEditing ? (
          layout.data.name
        ) : (
          <S.EditLayoutWrapper>
            <StyledInput
              autoFocus
              value={newLayoutName}
              style={{ height: '20px', border: 'var(--foreground-form-border)' }}
              data-testid="edit-layout-input"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewLayoutName(e.target.value)}
              onKeyDown={onKeyDown}
              onClick={(e: React.MouseEvent<HTMLInputElement>) => {
                e.stopPropagation();
                e.preventDefault();
              }}
            />
            <ConfirmInputButton
              style={{ position: 'relative', right: '20px' }}
              data-testid="confirm-btn"
              disabled={newLayoutName === '' || newLayoutName === 'Default'}
              onClick={(e: React.SyntheticEvent) => {
                e.preventDefault();
                e.stopPropagation();
                setIsEditing(false);
                onRename(layout, newLayoutName);
              }}
            >
              <Done style={{ color: 'var(--text)', fontSize: '16px' }} />
            </ConfirmInputButton>
          </S.EditLayoutWrapper>
        )}
      </S.Left>

      {moreMenuOptions && (
        <DropdownMenu data-testid="more-btn" menuMarginTop={10} styles={dropdownMenuStyles}>
          {renderLayoutOptions(moreMenuOptions, layout)}
        </DropdownMenu>
      )}
      {children}
    </S.LayoutRowStyled>
  );
};
