import React from 'react';
import LoadingIcon, { LoadingIconProps } from 'src/LoadingIcon/LoadingIcon';
import styled from 'styled-components';
export interface Loading {
  text?: string;
  full?: boolean;
  style?: React.CSSProperties;
}
type LoadingProps = Loading & LoadingIconProps;

const baseStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};

const fullScreenStyle: React.CSSProperties = {
  width: '100%',
  height: '100%',
};

export const getLoadingStyle = (full?: boolean, styleFromProps?: React.CSSProperties) => {
  return full ? { ...baseStyle, ...fullScreenStyle, ...styleFromProps } : { ...baseStyle, ...styleFromProps };
};

const Loading: React.FunctionComponent<LoadingProps> = ({ show = false, type, size, style, full, text }) => {
  return show ? (
    <div data-testid="loading-indicator" style={getLoadingStyle(full, style)}>
      <LoadingIcon type={type} size={size} show={show} />
      {text && <LoadingText>{text}</LoadingText>}
    </div>
  ) : null;
};

const LoadingText = styled.p`
  color: var(--foreground-form);
  text-transform: capitalize;
  font-size: var(--loading-icon-text-font-size);
`;

export default Loading;
