import { clone } from 'lodash';
import {
  FormFieldsType,
  FormValuesType,
  FormFieldType,
  FieldRule,
  FieldRuleType,
  FieldRuleProps,
} from 'src/components/forms/form-elements/FormElement.model';

interface GetFieldErrorMessage {
  value: any;
  validator?: (arg: any) => boolean;
  errorMessage?: string;
}

interface GetErrorMessageByDataType {
  field: FormFieldType;
  value?: boolean | string | number;
}

interface RequiredFieldValidationObject {
  valid: boolean;
  errorMessage: string | undefined;
}

type CustomErrorTypes = 'bit' | 'decimal';

type RuleActionFunction = (rule: FieldRule, groupName?: string) => void;

type RuleActions = {
  [key in FieldRuleType]: RuleActionFunction;
};

// Tested ✅
export const dataTypeErrors = (value?: boolean | string | number, displayName?: string) => ({
  bit: getFieldErrorMessage({
    value,
    validator: bitValidator,
    errorMessage: `${displayName} must be true or false`,
  }),
  decimal: getFieldErrorMessage({
    value,
    validator: decimalValidator,
    errorMessage: `${displayName} must be a number`,
  }),
});

// Tested ✅
export const bitValidator = (v: boolean | string) => (typeof v === 'boolean' ? Boolean(v) : false);

// Tested ✅
export const decimalValidator = (v: any) => {
  const value_: any = String(v).replace(/,/g, '');
  return !isNaN(value_);
};

// Tested ✅
export const checkRequiredField = ({
  displayName,
  value,
}: {
  displayName: string;
  value: number | string | boolean | undefined;
}): RequiredFieldValidationObject => {
  const requiredFieldIsEmpty = value === '' || value === undefined;
  if (requiredFieldIsEmpty) {
    return {
      valid: false,
      errorMessage: `${displayName} is required`,
    };
  }

  return {
    valid: true,
    errorMessage: undefined,
  };
};

// Tested ✅
export const getFieldErrorMessage = ({
  value,
  validator,
  errorMessage = 'Invalid value',
}: GetFieldErrorMessage): string | undefined => {
  const valueIsInvalid = value && validator && !validator(value);
  return valueIsInvalid ? errorMessage : undefined;
};

// Tested ✅
export const getErrorMessageByDataType = ({ field, value }: GetErrorMessageByDataType): string | undefined => {
  if (field?.required) {
    const requiredError: RequiredFieldValidationObject = checkRequiredField({ displayName: field.displayName, value });
    if (!requiredError.valid) {
      return requiredError.errorMessage;
    }
  }
  if (field?.isInvalid) {
    return `${field.displayName} has invalid value`;
  }
  const shouldUseCustomError = field?.dataType === 'bit' || field?.dataType === 'decimal';

  if (shouldUseCustomError) {
    return dataTypeErrors(value, field.displayName)[field.dataType as CustomErrorTypes];
  }

  return undefined;
};

export const validateForm = (
  formValues: { [key: string]: string | number | boolean | any },
  formFields: FormFieldsType,
) => {
  const errors =
    formValues &&
    Object.entries(formValues).reduce((acc, [key, value]: [string, any]) => {
      const field: FormFieldType = formFields[key];

      const errorMessage = getErrorMessageByDataType({ field, value });
      if (!errorMessage) {
        return acc;
      }
      return {
        ...acc,
        [key]: errorMessage,
      };
    }, {});

  const valid = errors && Object.keys(errors).length === 0;
  return { errors, valid };
};

// Tested ✅
export const getErrorsPerFormGroup = (formGroupsState: { [key: string]: FormValuesType }) =>
  formGroupsState &&
  Object.entries(formGroupsState).reduce((acc, [key, value]) => {
    const validation = validateForm(value.formValues, value.formFields);
    if (validation?.errors && Object.keys(validation.errors).length === 0) {
      return acc;
    }
    return {
      ...acc,
      [key]: validation.errors,
    };
  }, {});

export const getErrorsPerFormField = (formGroupsState: FormValuesType) => {
  const validation = validateForm(formGroupsState.formValues, formGroupsState.formFields);
  if (Object.keys(validation.errors).length === 0) {
    return {};
  }
  return validation.errors;
};

const evalOptions = (str: string) => {
  const strSplit = str.split('_');
  return strSplit[1]?.replace(/^./, strSplit[1][0].toUpperCase());
};

export const applyFieldRules = ({ fieldName, fieldRules, formValues, objectFormField, onEdit }: FieldRuleProps) => {
  const finalFields: any = {};
  const finalValues: any = {};

  const ruleActions: RuleActions = {
    [FieldRuleType.Value]: (rule: any) => {
      if (rule.ruleFn && typeof rule.ruleFn === 'function') {
        try {
          const { callFunc, errorParams } = validateFieldRuleParams(rule, formValues);
          // rule_target is to check if the rules have single field_rule_target
          const rule_target = rule?.field_rule_target?.split('/').pop();
          // is_rule_targets is to check if the rules have multiple field_rule_target and either of them having value as 0.
          const is_rule_targets = rule?.field_rule_targets?.map((target: any) => {
            const name = target?.split('/').pop();
            return formValues[name] === '';
          });
          if (
            onEdit &&
            (formValues.hasOwnProperty(rule_target) || is_rule_targets?.includes(true)) &&
            formValues[rule_target] === ''
          ) {
            const nextValue = rule.ruleFn({ ...formValues, ...finalValues[rule?.field_rule_target] });
            if (callFunc) {
              const targetFields = rule?.field_rule_target ? [rule?.field_rule_target] : rule?.field_rule_targets;
              targetFields.map((eachField: string) => {
                finalValues[eachField] = nextValue;
              });
            }
          } else if (!onEdit) {
            Object.keys(formValues as any).map((key: any) => {
              if (objectFormField[key]?.dataType == 'decimal') {
                formValues[key] = formValues[key]?.replaceAll(/,/g, '');
              }
            });
            const nextValue = rule.ruleFn({ ...formValues, ...finalValues[rule?.field_rule_target] });
            if (callFunc) {
              const targetFields = rule?.field_rule_target ? [rule?.field_rule_target] : rule?.field_rule_targets;
              targetFields.map((eachField: string) => {
                finalValues[eachField] = nextValue;
              });
            }
          } else if (errorParams.length > 0) {
            console.error(
              `${fieldName}: invalid params in field rule Function is/are:`,
              errorParams,
              `Failed rule function is: ${rule.ruleFn}`,
            );
          }
        } catch (error) {
          console.error(`${fieldName}: Invalid rule or values for Value rule.`, error);
        }
      }
    },
    [FieldRuleType.Required]: (rule: any) => {
      if (rule.ruleFn && typeof rule.ruleFn === 'function') {
        try {
          const targetFields = rule?.field_rule_target ? [rule?.field_rule_target] : rule?.field_rule_targets;
          const nextRequired = rule.ruleFn(formValues) as boolean;
          targetFields.map((eachField: any) => {
            finalFields[eachField] = {
              ...finalFields[eachField],
              required: nextRequired,
            };
          });
        } catch (error) {
          console.error(`${fieldName}: Invalid rule or values for Required rule`);
        }
      }
    },
    [FieldRuleType.ReadOnly]: (rule: any) => {
      if (rule.ruleFn && typeof rule.ruleFn === 'function') {
        try {
          const targetFields = rule?.field_rule_target ? [rule?.field_rule_target] : rule?.field_rule_targets;
          const nextDisabled = rule.ruleFn(formValues) as boolean;
          targetFields.map((eachField: any) => {
            finalFields[eachField] = {
              ...finalFields[eachField],
              disabled: nextDisabled,
              readonly: nextDisabled,
            };
          });
        } catch (error) {
          console.error(`${fieldName}: Invalid rule or values for ReadOnly rule`);
        }
      }
    },
    [FieldRuleType.Hidden]: (rule: any) => {
      if (rule.ruleFn && typeof rule.ruleFn === 'function') {
        try {
          const nextHidden = rule.ruleFn(formValues) as boolean;
          const targetFields = rule?.field_rule_target ? [rule?.field_rule_target] : rule?.field_rule_targets;
          targetFields.map((eachField: any) => {
            finalFields[eachField] = {
              ...finalFields[eachField],
              hidden: nextHidden,
            };
          });
        } catch (error) {
          console.error(`${fieldName}: Invalid rule or values for Hidden rule`);
        }
      }
    },
    [FieldRuleType.Filter]: (rule: any) => {
      if (rule.ruleFn && typeof rule.ruleFn === 'function') {
        const targetFields = rule?.field_rule_target ? [rule?.field_rule_target] : rule?.field_rule_targets;
        const options: { [key: string]: { name: string; value: string } } = {};
        //Loop through target fields and filter out options based on field rules to be populated into dropdown
        targetFields.map((eachTargetField: string) => {
          let eachField = eachTargetField;
          if (eachField.startsWith('fields')) {
            eachField = eachField.replace('fields/', '');
          }
          if (
            objectFormField[eachField] &&
            objectFormField[eachField].options &&
            Object.keys(objectFormField[eachField].options).length
          ) {
            Object.keys(objectFormField[eachField].options).map((eachKey: any) => {
              const filterValues = rule.ruleFn({
                ...formValues,
                [eachField]: 'lookups/' + objectFormField[eachField].options[eachKey].value,
              });
              if (filterValues && filterValues.length > 0) {
                filterValues?.map((eachFilterValue: any) => {
                  options[eachFilterValue] = { name: evalOptions(eachFilterValue), value: eachFilterValue };
                });
              }
            });

            finalFields[eachTargetField] = {
              ...finalFields[eachTargetField],
              options,
            };
          }
        });
      }
    },
    [FieldRuleType.Valid]: (rule: any) => {
      if (rule.ruleFn && typeof rule.ruleFn === 'function') {
        const fieldRuleValues = clone(formValues);
        if (objectFormField[fieldName]?.dataType == 'decimal' && objectFormField[fieldName]?.format == '') {
          fieldRuleValues[fieldName] = fieldRuleValues[fieldName]?.replaceAll(',', '');
        }
        const nextValid = rule.ruleFn(fieldRuleValues) as boolean;
        const targetFields = rule?.field_rule_target ? [rule?.field_rule_target] : rule?.field_rule_targets;

        targetFields.map((eachField: any) => {
          finalFields[eachField] = {
            ...finalFields[eachField],
            isInvalid: !nextValid,
          };
        });
      }
      return null;
    },
  };
  if (fieldRules) {
    fieldRules.map((eachRule: any) => {
      if (ruleActions[eachRule.ruleType as FieldRuleType]) {
        ruleActions[eachRule.ruleType as FieldRuleType](eachRule);
      }
    });
    return { finalFields, finalValues };
  }

  return null;
};

const validateFieldRuleParams = (rule: any, formValues: any) => {
  let callFunc = true;
  const errorParams: any = [];
  const paramsList = getFunctionParamNames(rule.ruleFn);
  paramsList.forEach((element: string) => {
    if (!formValues.hasOwnProperty(element)) {
      callFunc = false;
      errorParams.push(element);
    }
  });
  return { callFunc, errorParams };
};

export const getFunctionParamNames = (func: any) => {
  const STRIP_COMMENTS = /((\/\/.*$)|(\/\*[\s\S]*?\*\/))/gm;
  const ARGUMENT_NAMES = /([^\s,]+)/g;
  const fnStr =
    func &&
    func
      .toString()
      .replace(STRIP_COMMENTS, '')
      .match(/([^=>]+)/)[1]; // taking string until '=>'
  /* below computation will match for object as parameters or no paramter eg. 
  1. if passed : ({loan_trade_Form})=> void - it will return [loan_trade_form]
  2. if passed : ()=> true - it will return null*/
  let result = fnStr && fnStr.slice(fnStr.indexOf('(') + 1, fnStr.indexOf(')'));
  if (result && result.match(/{(.*)}/)) result = result.match(/{(.*)}/)[1].match(ARGUMENT_NAMES);
  if (!result) result = [];
  return result;
};

// Tested ✅
export const populateFormGroupsWithErrors = (
  formValidationErrors: any,
  formGroupsState: { [key: string]: FormValuesType },
) =>
  formValidationErrors &&
  Object.entries(formValidationErrors).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: {
        ...acc[`${key}`],
        formErrors: {
          errors: value,
          valid: false,
        },
      },
    }),
    formGroupsState,
  );

export const populateFormWithErrors = (formValidationErrors: any, formGroupsState: FormValuesType) => {
  return { ...formGroupsState, formErrors: { errors: formValidationErrors, valid: false } };
};

export const getFormGroupWithErrors = (nextFormGroupsState: any) => {
  const formValidationErrors = getErrorsPerFormGroup(nextFormGroupsState);
  return populateFormGroupsWithErrors(formValidationErrors, nextFormGroupsState);
};
