import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as S from './order-selection-panel.style';
import { Props, ReduxProps, StaticTabOrder } from './order-selection-panel.model';
import {
  EditableTabKeys,
  primaryActionConfig,
} from 'src/components/glide-object-manager/components/glide-object-manager.model';
import TabPanel from 'devextreme-react/tab-panel';
import { RootState } from 'src/reducers/rootReducer';
import { connect, useSelector } from 'react-redux';
import { glideDataTypeMap } from '@virtus/components/DxDataGrid/utils/mapSchemaGlide';
// import { notificationMessageMap, notificationSelector } from 'src/reducers/notifications';
import GlideObjectManagerComponent from 'src/components/glide-object-manager/components/glide-object-manager';
import { getFormPropsForGlideObjectManager } from 'src/components/forms/glide-data-content';
import { GlideOrderDisplayView } from 'src/models/order/glideOrderDisplayView.api.model';
import { ViewElement } from 'src/models/api/viewElement';
import { glideQuerySelectorViewName, isPendingQuerySelector } from 'src/api/query';
import { dispatchActions, store } from 'src/app/store';
import {
  selectCVCData,
  selectComponents,
  selectCurrentObjectInView,
  selectViewComponent,
} from 'src/reducers/components';
import { endpoints } from 'src/api/constants';
import { actionTypes, cancelQuery } from 'redux-query';
import { VIEW_INSPECTOR } from 'src/components/inspectors/glide-object-inspector/view-inspector';
import { Changes } from 'src/components/glide-object-manager/model';
import { getLastElement } from '@virtus/common/utils/arrayLastElement';

export const sortTabDataSource = (dataSourceValues: { [key: string]: any }[]) => {
  const sortedTabs = StaticTabOrder.reduce((acc: any, value: any) => {
    const indexOfVal = dataSourceValues.findIndex((element: any) => element.key === value);
    if (indexOfVal > -1) {
      return [...acc, ...dataSourceValues.splice(indexOfVal, 1)];
    }
    return acc;
  }, []);
  return [...sortedTabs, ...dataSourceValues];
};

export const getTabPanelDataSource = (orderDisplayViewData: GlideOrderDisplayView, bottomPanelFields: any[]) => {
  const { workflow_status_history, workflow_transitions, ...orderDisplayGroups } = orderDisplayViewData;
  const tabValues = Object.values(orderDisplayGroups).reduce((acc, values) => {
    const tabPanelKeys = (values as any[]).reduce((accKey: any, field: ViewElement) => {
      if (typeof field.field === glideDataTypeMap.String) return accKey;
      const instance_uri: string = Object.keys(field.field)[0];
      const fieldName = instance_uri.replace('fields/', '');
      const firstField = Object.values(field.field)[0];
      const dataType: string = firstField.data_type.replace('lookups/', '');
      const displayName: string = firstField.display_name;
      if (dataType === 'objectcollection') {
        return [...accKey, { text: displayName, title: displayName, key: fieldName }];
      }
      return accKey;
    }, []);
    return [...acc, ...tabPanelKeys];
  }, []);

  const dataSource = [...bottomPanelFields].map(item => {
    if ((tabValues as any[]).find((tab: any) => tab.key === item.key)) {
      return { ...item };
    }
    return { ...item, disabled: Object.keys(item).includes('disabled') ? item.disabled : true };
  });

  return sortTabDataSource(dataSource);
};

// const isBottomPanelEdited = (tabRef: any) => {
//   const element = tabRef?.current?.instance.element().querySelectorAll('[title="Save changes"][tabindex="0"]')[0];
//   if (element) {
//     return !element.classList.contains('dx-state-disabled');
//   }
//   return false;
// };

export const getBottomGridData = (object_uri: string, object_field_name: string, uri: string) => {
  const endpoint = uri?.includes('instance/') ? endpoints.clientViews.root : endpoints.getCollection.rootObject;
  const body = uri?.includes('instance/') ? { uri: uri, object_uri: object_uri } : { object_uri, object_field_name };
  store.dispatch(cancelQuery('orderBottomPanelGrid'));
  // ⚠️ Checkmarx Vulnerability ID 116123 - Information Exposure Through an Error Message
  try {
    dispatchActions.db.fetch({
      endpoint,
      body,
      options: { method: 'GET' },
      queryKey: 'orderBottomPanelGrid',
    });
  } catch (error) {
    console.error(`Error fetching Grid Data: ${error}`, error);
  }
};

export const OrderSelectionPanel = ({
  orderDetails,
  bottomPanelFields,
  bottomDataGrid,
  // notification,
  isBottomGridDataPending,
  selectedRowData,
  clientViewUri,
  isExpanded,
  bottomPanelComponent,
  currentObjectInView,
  resetselectedRecord,
}: Props) => {
  const [currentTab, setCurrentTab] = useState<string | undefined>(undefined);
  const [currentTabUri, setCurrentTabUri] = useState<string | undefined>(undefined);
  const gridRef = useRef(null);
  const tabRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [displayViewUri, setDisplayViewUri] = useState('');
  const [tabDataSource, setTabDataSource] = useState<any>(sortTabDataSource([...bottomPanelFields]));

  const isdeletePending: boolean = useSelector((state: RootState) =>
    isPendingQuerySelector(state, 'deleteObjectInCollection'),
  );

  const handleTabChange = useCallback(
    (event: any) => {
      const currentTabKey = event?.key || '';
      setCurrentTab(currentTabKey);
      setCurrentTabUri(event.uri);
      resetselectedRecord();
      if (bottomPanelComponent?.instanceUri === orderDetails.uri) {
        getBottomGridData(orderDetails.uri, currentTabKey, event.uri);
      } else if (bottomPanelComponent?.instanceUri && currentTab !== currentTabKey) {
        getBottomGridData(bottomPanelComponent?.instanceUri, currentTabKey, event.uri);
      }
    },
    [orderDetails?.uri, isExpanded, bottomPanelComponent?.instanceUri, currentTab],
  );

  //code has been for future reference when decoupling handle confirmation popup
  // const closeDialog = useCallback(
  //   (event: any) => {
  //     if (event) {
  //       handleTabChange(event.itemData);
  //       setSelectedIndex(event.itemIndex);
  //     }
  //   },
  //   [handleTabChange],
  // );

  // const { DialogComponent: CancelEditConfirmDialog, onDispatcherClick: handleOnCancelScenarioWithConfirmation } =
  //   useConfirmationDialog({
  //     onClick: closeDialog,
  //     headerText: SAVE_CHANGE,
  //     bodyTextContent: CONFIRMATION_TEXT_CONTENT,
  //     bodyContentAlign: 'left',
  //   });

  // const customDxToolbarButtonsActions = useMemo(
  //   () => ({
  //     saveButton: () => {
  //       saveOrderCollection(gridRef, tabDataSource, currentTab as string, orderDetails);
  //     },
  //   }),
  //   [currentTab, orderDetails, tabDataSource],
  // );

  // keeping it for future referance
  // useEffect(() => {
  //   if (
  //     notification?.type === 'success' &&
  //     notification.title === notificationMessageMap.MutateSuccessMessage &&
  //     currentTab &&
  //     currentTabUri
  //   ) {
  //     getBottomGridData(orderDetails.uri, currentTab, currentTabUri);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [notification]);

  useEffect(() => {
    if (
      orderDetails?.data &&
      bottomPanelComponent?.instanceUri === orderDetails.uri &&
      bottomPanelComponent?.instanceUri !== displayViewUri
    ) {
      setTabDataSource(getTabPanelDataSource(orderDetails.data, bottomPanelFields));
    }
  }, [bottomPanelFields, orderDetails?.data]);

  useEffect(() => {
    if (orderDetails?.uri) {
      setDisplayViewUri(orderDetails?.uri);
    }
  }, [orderDetails?.uri]);

  useEffect(() => {
    if (currentObjectInView) {
      setSelectedIndex(0);
      setCurrentTab(tabDataSource[0].key);
      setCurrentTabUri(tabDataSource[0].uri);
    }
  }, [currentObjectInView]);

  useEffect(() => {
    if (isExpanded && currentTab && currentTabUri && orderDetails?.uri) {
      if (
        bottomPanelComponent?.instanceUri &&
        ((bottomPanelComponent?.instanceUri === orderDetails.uri &&
          bottomPanelComponent?.instanceUri !== displayViewUri) ||
          bottomDataGrid?.schema?.length === 0)
      ) {
        getBottomGridData(orderDetails.uri, tabDataSource[0].key as string, tabDataSource[0].uri);
      }
    }
  }, [isExpanded, orderDetails?.uri, currentTab, currentTabUri, bottomPanelComponent?.instanceUri]);

  const itemTitleRender = useCallback((props: any) => {
    const GridTitle = props?.text || '';
    return <span>{GridTitle}</span>;
  }, []);

  const handleOnGridViewChangesSaved = useCallback(
    ({ changedRows, newRows, deleteRows, clientViewUri }: Changes) => {
      const hasChanges = Object.keys(changedRows).length > 0;
      const objectUpdating = currentTab
        ?.toLowerCase()
        .split('_')
        .map(s => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
      if (deleteRows?.length > 0) {
        dispatchActions.db.update({
          endpoint: endpoints.delete.rootObject,
          body: { object_uri: orderDetails?.uri ?? displayViewUri, delete_content: { fund_allocations: deleteRows } },
          options: {
            method: 'POST',
            // ⚠️ Pen test Issue Vulnerability ID 1000136466 - Potentially harmful HTTP methods enabled
            headers: { 'X-Http-Method-Override': 'DELETE' },
          },
          queryKey: 'deleteObjectInCollection',
          meta: {
            object_field_name: currentTab,
            notification: {
              [actionTypes.MUTATE_START]: `Saving ${objectUpdating}`,
              [actionTypes.MUTATE_SUCCESS]: `${objectUpdating} updated`,
            },
          },
        });
      }
      if (hasChanges || newRows.length > 0) {
        dispatchActions.db.update({
          endpoint: endpoints.update.rootObject,
          body: {
            object_uri: orderDetails?.uri ?? displayViewUri,
            [currentTab as string]: changedRows,
            new_objects: newRows,
          },
          queryKey: 'orderBottomPanelGrid',
          options: {
            method: 'POST',
            // ⚠️ Pen test Issue Vulnerability ID 1000136466 - Potentially harmful HTTP methods enabled
            headers: { 'X-Http-Method-Override': 'PUT' },
          },
          meta: {
            object_field_name: currentTab,
            changedRows,
            newRows,
            notification: {
              [actionTypes.MUTATE_START]: `Saving ${objectUpdating}`,
              [actionTypes.MUTATE_SUCCESS]: `${objectUpdating} updated`,
            },
          },
          transform: (body: any) => {
            let data = {};
            if (objectUpdating) data = { [objectUpdating]: JSON.parse(body.data) };
            return {
              views: {
                [clientViewUri]: {
                  GridDataToUpdate: {
                    key: orderDetails?.uri ?? displayViewUri,
                    data: data,
                  },
                },
              },
            };
          },
          update: {
            views: (prev: any, next: any) => {
              return {
                ...prev,
                [clientViewUri]: {
                  ...prev[clientViewUri],
                  GridDataToUpdate: next[clientViewUri].GridDataToUpdate,
                },
              };
            },
          },
        });
      }
    },
    [currentTab, orderDetails?.uri],
  );

  const bottomGrid = useCallback(() => {
    if ((!orderDetails?.uri && !Object.keys(bottomDataGrid).length) || !isExpanded) {
      return undefined;
    }

    return (
      <>
        {bottomDataGrid && (
          <S.BottomPanelGridOverride>
            <GlideObjectManagerComponent
              ref={gridRef}
              fieldName={currentTab as string}
              onRefresh={() =>
                getBottomGridData(orderDetails?.uri || displayViewUri, currentTab as string, currentTabUri as string)
              }
              loading={isBottomGridDataPending || isdeletePending}
              dataSource={{
                data: bottomDataGrid?.data,
                schema: bottomDataGrid?.schema,
                fieldRules: bottomDataGrid?.fieldRules,
              }}
              formProps={
                selectedRowData &&
                getFormPropsForGlideObjectManager({ field: currentTab || '', formValues: selectedRowData })
              }
              displayViewData={bottomDataGrid}
              selectedRowData={selectedRowData}
              editObjectIcons={primaryActionConfig[currentTab as EditableTabKeys]}
              removeIconsConfig={['revertButton']}
              onGridViewChangesSaved={handleOnGridViewChangesSaved}
              showHeader={false}
              realtimeData={true}
              isBottomPanel={true}
            />
          </S.BottomPanelGridOverride>
        )}
      </>
    );
  }, [isBottomGridDataPending, orderDetails?.uri, bottomDataGrid, currentTab, selectedRowData, isExpanded]);

  const onTitleClickHandler = useCallback(
    (e: any) => {
      const { itemData } = e;
      // if (isBottomPanelEdited(tabRef)) {
      //   //handleOnCancelScenarioWithConfirmation(e);
      //   return;
      // } else
      if (itemData && currentTab !== itemData.key) {
        handleTabChange(itemData);
      }

      // if (!isBottomPanelEdited(tabRef)) {
      setSelectedIndex(e.itemIndex);
      // }
      dispatchActions.components.updateView('bottomPanelExpandedState', clientViewUri, { isExpanded: true });
    },
    [
      currentTab,
      //handleOnCancelScenarioWithConfirmation,
      handleTabChange,
      isExpanded,
    ],
  );

  const onContentReady = useCallback(
    (e: any) => {
      // ⚠️ Checkmarx Vulnerability ID 116123 - Information Exposure Through an Error Message
      try {
        const selectedItem = e.component.instance().option('selectedItem');
        if (!selectedItem) return;
        if (!selectedItem.disabled && displayViewUri === orderDetails?.uri) {
          setCurrentTab(selectedItem.key);
          setCurrentTabUri(selectedItem.uri);
        }
      } catch (error) {
        console.error(`Error setting Current Tab`);
      }
    },
    [displayViewUri, orderDetails?.uri],
  );

  return (
    <>
      {/* <CancelEditConfirmDialog /> */}
      <S.OrderSelectionTabHeader data-testid="order-bottom-tab-panel" className="order-bottom-tab-panel">
        <S.TabPanelOverride>
          <TabPanel
            ref={tabRef}
            dataSource={tabDataSource}
            itemTitleRender={itemTitleRender}
            itemRender={bottomGrid}
            onTitleClick={onTitleClickHandler}
            onContentReady={onContentReady}
            selectedIndex={selectedIndex}
          />
        </S.TabPanelOverride>
      </S.OrderSelectionTabHeader>
    </>
  );
};

const mapStateToProps = (state: RootState, ownProps: any): ReduxProps => {
  const { clientViewUri } = ownProps;
  const components = selectComponents(state);
  const currentObjectInView = { uri: getLastElement(selectCurrentObjectInView(state)?.uri) };
  return {
    orderDetails: selectCVCData(state, VIEW_INSPECTOR),
    bottomDataGrid: glideQuerySelectorViewName(state, 'orderBottomPanelGrid'),
    // notification: notificationSelector(state),
    isExpanded: components?.viewComponents?.[clientViewUri as string]?.['bottomPanelExpandedState']?.['isExpanded'],
    bottomPanelComponent: selectViewComponent(state, 'bottomPanel', clientViewUri),
    currentObjectInView: currentObjectInView,
    resetselectedRecord: () =>
      dispatchActions.components.resetBottomPanel('selectedBottomPanelRecord', clientViewUri, {}),
    isBottomGridDataPending:
      isPendingQuerySelector(state, 'InspectorLockStatus') ||
      isPendingQuerySelector(state, 'rightSideInspector') ||
      isPendingQuerySelector(state, 'orderBottomPanelGrid'),
  };
};
export default connect(mapStateToProps)(OrderSelectionPanel);
