import { call, put, putResolve, select, takeLatest } from 'redux-saga/effects';
import {
  InspectorFormDiscardActionPayload,
  inspectorFormData,
  inspectorFormsAction,
  selectCurrentForm,
  selectGlobalForm,
  setInspectorFormEdit,
} from 'src/reducers/inspectorForm.reducer';
import { actionTypes, mutateAsync } from 'redux-query';
import { NotificationsAction } from 'src/reducers/notifications';
import { glideQuery } from 'src/api/query';
import {
  CurrentObjectInView,
  selectCurrentObjectInView,
  selectStaticComponent,
  toggleComponentDisplayAction,
  updateComponentAction,
  updateComponentViewAction,
  ComponentPropsType,
  selectComponents,
  selectCurrentGlobalObjectInView,
  selectCurrentObjectInViewList,
  GlobalCurrentObjectInView,
} from 'src/reducers/components';
import {
  ModalFormDiscardActionPayload,
  modalFormData,
  modalFormsAction,
  resetModalForm,
  selectModalForm,
  setModalForm,
  setModalFormEdit,
} from 'src/reducers/modalForm.reducer';
import { activeTabSelector, selectCVC } from 'src/reducers/tabs';
import { VIEW_INSPECTOR } from 'src/components/inspectors/glide-object-inspector/view-inspector';
import { ClientViewConfigurationData } from 'src/components/glide-view/glide-view.model';
import { parseUriParams } from 'src/mappers/common-mapper-functions';
import {
  MODAL_INSPECTOR,
  selectModalInspectorData,
  selectModalInspectorObjUri,
} from 'src/components/inspectors/glide-object-inspector/modal-inspector';
import {
  CreditDetailFormDiscardActionPayload,
  creditDetailFormData,
  creditDetailFormsAction,
  resetCreditDetailForm,
  selectCreditDetailForm,
  setCreditDetailForm,
  setCreditDetailFormEdit,
} from 'src/reducers/creditDetailForm.reducer';
import {
  CREDIT_DETAIL_INSPECTOR,
  selectCreditDetailInspectorData,
} from 'src/components/inspectors/glide-object-inspector/credit-detail-inspector';
import { parseDisplayViewData } from 'src/api/queries/display-view';
import { onInspectorRelease } from './glide-object-handler.saga';
import { getLastElement } from '@virtus/common/utils/arrayLastElement';

export enum ActionForm {
  ACTION_FORM_DISCARD_CHANGES = 'ACTION_FORM_DISCARD_CHANGES',
  ACTION_FORM_CLOSE = 'ACTION_FORM_CLOSE',
}

interface Field {
  display_name: string;
}

interface ObjectType {
  uri?: any;
  field: Record<string, Field>;
  value: any;
}

interface ViewInspectorData {
  [key: string]: ObjectType[];
}

const New_URIS_TO_OBJ_TYPE_SAVE_URI_MAPPING: { [Name: string]: any } = {
  'instance/actions/legal_entity_new': 'legal_entities|instance/actions/legal_entity_create',
  'instance/actions/new_deal': 'deals|instance/actions/create_deal',
};

export interface ActionFormDiscardActionPayload {
  displayActionConfirmationDialog: Function;
}

export function* onInspectorSaveForm(action: any) {
  const inspectorForm: inspectorFormData =
    action?.payload?.clientViewUri === 'global' ? yield select(selectGlobalForm) : yield select(selectCurrentForm);
  const CurrentObjectInViewList: CurrentObjectInView = yield select(selectCurrentObjectInView);
  const currentObjectInView: GlobalCurrentObjectInView =
    action?.payload?.clientViewUri === 'global'
      ? yield select(selectCurrentGlobalObjectInView)
      : { uri: getLastElement(CurrentObjectInViewList?.uri) };
  const clientViewConfiguration: ClientViewConfigurationData = yield select(selectCVC);

  if (
    Object.keys(inspectorForm?.initErrors.errors)?.length > 0 ||
    Object.keys(inspectorForm?.formErrors.errors)?.length > 0
  ) {
    yield put({
      type: NotificationsAction.VALIDATION_ERROR_NOTIFICATION,
      payload: {
        errorMessage:
          Object.keys(inspectorForm.initErrors)
            // @ts-ignore: FIXME
            .map(key => Object.values(inspectorForm.initErrors[key]))
            .join('\n') ||
          Object.keys(inspectorForm.formErrors)
            // @ts-ignore: FIXME
            .map(key => Object.values(inspectorForm.formErrors[key]))
            .join('\n'),
      },
    });
  } else if (inspectorForm._dbSavePending) {
    const storeViewName = action?.payload?.clientViewUri === 'global' ? 'global' : clientViewConfiguration.uri;

    yield putResolve(
      mutateAsync(
        glideQuery({
          endpoint: '/glide/update',
          // ⚠️ Pen test Issue Vulnerability ID 1000136466 - Potentially harmful HTTP methods enabled
          options: { method: 'POST', headers: { 'X-Http-Method-Override': 'PUT' } },
          body: { [currentObjectInView.uri]: inspectorForm.formChanges },
          update: {
            views: (prev: any, next: any) => {
              const changes: Record<string, any> = {};
              const viewInspectorData: ViewInspectorData = next[storeViewName][VIEW_INSPECTOR].data;

              for (const group in viewInspectorData) {
                for (const object of viewInspectorData[group]) {
                  changes[object.field[Object.keys(object.field)[0]].display_name] = object?.uri
                    ? { display_name: object.value, uri: object?.uri }
                    : object.value;
                }
              }
              return {
                ...prev,
                [storeViewName]: {
                  ...prev[storeViewName],
                  [VIEW_INSPECTOR]: {
                    ...prev[storeViewName][VIEW_INSPECTOR],
                    ...next[storeViewName][VIEW_INSPECTOR],
                  },
                  GridDataToUpdate: { key: next[storeViewName][VIEW_INSPECTOR].uri, data: { ...changes } },
                },
              };
            },
          },
          transform: (body: any) => {
            return {
              views: {
                [storeViewName]: {
                  [VIEW_INSPECTOR]: parseDisplayViewData(body),
                },
              },
            };
          },
          // keepig it for future referance
          // storeViewName: action?.payload?.clientViewUri === 'global' ? 'global' : clientViewConfiguration.uri,
          // storeViewProp: VIEW_INSPECTOR,
        }),
      ),
    );
    yield call(onInspectorRelease);
  }
}

export function* onModalSaveForm(action: any): any {
  const modalForm: any = yield select(selectModalForm);
  const instanceUri = action.payload.instanceUri;
  if (
    Object.keys(modalForm.uri[instanceUri].initErrors.errors)?.length > 0 ||
    Object.keys(modalForm.uri[instanceUri].formErrors.errors)?.length > 0
  ) {
    yield put({
      type: NotificationsAction.VALIDATION_ERROR_NOTIFICATION,
      payload: {
        errorMessage:
          Object.keys(modalForm.uri[instanceUri].initErrors)
            // @ts-ignore: FIXME
            .map(key => Object.values(modalForm.uri[instanceUri].initErrors[key]))
            .join('\n') ||
          Object.keys(modalForm.uri[instanceUri].formErrors)
            // @ts-ignore: FIXME
            .map(key => Object.values(modalForm.uri[instanceUri].formErrors[key]))
            .join('\n'),
      },
    });
  } else if (modalForm.uri[instanceUri]._dbSavePending) {
    const components: ComponentPropsType = yield select(selectComponents);
    // const currentObjectInView: CurrentObjectInView = yield select(selectCurrentObjectInView);
    const clientViewConfiguration: ClientViewConfigurationData = yield select(selectCVC);
    const modalFormUri = components.modalInspector.modalInspectorObjUri;
    if (
      components.modalInspector[instanceUri]?.isVisible &&
      (components.modalInspector[instanceUri]?.uri?.includes('global_actions') || modalFormUri == instanceUri)
    ) {
      const modalInspectorData = yield select(selectModalInspectorData);
      const defaultValueUri =
        modalInspectorData[instanceUri]?.displayViewData?.uri || modalInspectorData[instanceUri]?.displayView?.uri;
      let newObjectType = defaultValueUri?.split('?')[0]?.lastSplitValue();
      if (modalFormUri !== '' && (defaultValueUri == undefined || defaultValueUri == '')) {
        newObjectType = New_URIS_TO_OBJ_TYPE_SAVE_URI_MAPPING[modalFormUri]?.split('|')[0];
      }

      const defaultValuefromUri =
        modalInspectorData[instanceUri]?.displayViewData?.uri &&
        parseUriParams(modalInspectorData[instanceUri]?.displayViewData?.uri);

      let new_object_data = modalForm.uri[instanceUri].formChanges;
      new_object_data = {
        ...defaultValuefromUri,
        ...modalForm.uri[instanceUri].formChanges,
        behaviour: defaultValuefromUri?.['behaviour'],
      };

      yield putResolve(
        mutateAsync(
          glideQuery({
            endpoint: '/glide/new',
            body: { object_type: newObjectType, object_data: new_object_data },
            options: { method: 'POST' },
            update: {
              newObjectUri: (_: any, next: any) => next,
            },
            transform: (body: any) => ({ newObjectUri: { [newObjectType]: body?.uri[0] } }),
            meta: {
              notification: {
                [actionTypes.MUTATE_SUCCESS]: `${newObjectType} added successfully.`,
              },
            },
          }),
        ),
      );
      yield call(handleModalFormClose, { hideInspector: true, instanceUri: instanceUri });
    } else {
      yield putResolve(
        mutateAsync(
          glideQuery({
            endpoint: '/glide/update',
            // ⚠️ Pen test Issue Vulnerability ID 1000136466 - Potentially harmful HTTP methods enabled
            options: { method: 'POST', headers: { 'X-Http-Method-Override': 'PUT' } },
            body: { [instanceUri]: modalForm.uri[instanceUri].formChanges },
            update: {
              [MODAL_INSPECTOR]: (prev: any, next: any) => ({
                ...prev,
                [instanceUri]: {
                  ...next,
                },
              }),
              views: (prev: any, next: any) => ({
                ...prev,
                [clientViewConfiguration.uri]: {
                  ...prev[clientViewConfiguration.uri],
                  [MODAL_INSPECTOR]: next[clientViewConfiguration.uri][MODAL_INSPECTOR],
                },
              }),
            },
            transform: (body: any) => ({
              [MODAL_INSPECTOR]: parseDisplayViewData(body),
              views: {
                [clientViewConfiguration.uri]: {
                  [MODAL_INSPECTOR]: parseDisplayViewData(body),
                },
              },
            }),
          }),
        ),
      );
      yield call(handleModalFormClose, { hideInspector: false, instanceUri: instanceUri });
    }
  }
}

export function* onCreditDetailModalSaveForm(): any {
  const creditDetailForm: creditDetailFormData = yield select(selectCreditDetailForm);

  if (Object.keys(creditDetailForm?.formErrors.errors)?.length > 0) {
    yield put({
      type: NotificationsAction.VALIDATION_ERROR_NOTIFICATION,
      payload: {
        errorMessage: Object.keys(creditDetailForm.formErrors)
          // @ts-ignore: FIXME
          .map(key => Object.values(creditDetailForm.formErrors[key]))
          .join('\n'),
      },
    });
  } else if (creditDetailForm._dbSavePending) {
    const currentObjectInView: CurrentObjectInView = yield select(selectCurrentObjectInView);
    // const clientViewConfiguration: ClientViewConfigurationData = yield select(selectCVC);
    yield putResolve(
      mutateAsync(
        glideQuery({
          endpoint: '/glide/update',
          // ⚠️ Pen test Issue Vulnerability ID 1000136466 - Potentially harmful HTTP methods enabled
          options: { method: 'POST', headers: { 'X-Http-Method-Override': 'PUT' } },
          body: { [getLastElement(currentObjectInView?.uri)]: creditDetailForm.formChanges },
          update: {
            [CREDIT_DETAIL_INSPECTOR]: (_: any, next: any) => next,
          },
          transform: (body: any) => ({ [CREDIT_DETAIL_INSPECTOR]: parseDisplayViewData(body) }),
        }),
      ),
    );
    yield call(handleCreditDetailFormClose, false);
  }
}

export function* handleModalFormDiscardChanges(action: { payload: ModalFormDiscardActionPayload }): any {
  const modalForm: modalFormData = yield select(selectModalForm);
  const instanceUri = action.payload.objectInView;
  modalForm?.uri[instanceUri]?.hasChanged
    ? action.payload.displayModalConfirmationDialog(instanceUri)
    : yield call(handleModalFormClose, { hideInspector: action?.payload?.hideInspector, instanceUri: instanceUri });
}

export function* handleModalFormClose(action: any): any {
  const modalInspectorData = yield select(selectModalInspectorData);
  let instanceUri = action.instanceUri || action.payload.objectInView;
  const clientViewUri = action?.payload?.isGlobalViewInspectorFooter ? 'global' : yield select(activeTabSelector);
  if (instanceUri == '') {
    instanceUri = yield select(selectModalInspectorObjUri);
  }
  if (action.payload?.hideInspector || action.hideInspector) {
    yield put(
      updateComponentAction(MODAL_INSPECTOR, {
        [instanceUri]: { isVisible: false, uri: '' },
        modalInspectorObjUri: '',
      }),
    );
    const components: ComponentPropsType = yield select(selectComponents);
    const currentObjectInView: string[] = yield select(selectCurrentObjectInViewList);
    if (!components?.modalInspector[instanceUri]?.isGlobalAction) currentObjectInView.pop();
    yield put(
      updateComponentAction('actionForm', {
        target_uri: getLastElement(currentObjectInView),
      }),
    );
    yield put(updateComponentViewAction('currentObjectInView', clientViewUri, { uri: currentObjectInView }));

    yield put(setModalFormEdit(false, modalInspectorData[instanceUri]));
    yield put(resetModalForm(instanceUri));
  } else {
    yield put(setModalFormEdit(false, modalInspectorData[instanceUri] || modalInspectorData));
    yield put(resetModalForm(instanceUri));
    const modalInspectorDataForm = yield select(selectModalInspectorData);
    yield put(setModalForm(modalInspectorDataForm[instanceUri] || modalInspectorDataForm));
  }
}

export function* handleCreditDetailFormDiscardChanges(action: { payload: CreditDetailFormDiscardActionPayload }): any {
  const creditDetailForm: creditDetailFormData = yield select(selectCreditDetailForm);
  creditDetailForm.hasChanged
    ? action.payload.displayCreditDetailConfirmationDialog()
    : yield call(handleCreditDetailFormClose, action?.payload?.hideInspector);
}

export function* handleCreditDetailFormClose(action: any): any {
  if (action == true || action?.payload?.hideInspector) {
    yield put(updateComponentAction(CREDIT_DETAIL_INSPECTOR, { isVisible: false }));
    yield put(setCreditDetailFormEdit(false));
    yield put(resetCreditDetailForm());
  } else {
    yield put(setCreditDetailFormEdit(false));
    const creditDetailInspectorData = yield select(selectCreditDetailInspectorData);
    yield put(setCreditDetailForm(creditDetailInspectorData));
  }
}

export function* handleActionFormDiscardChanges(action: { payload: ActionFormDiscardActionPayload }): any {
  const actionForm = yield select(selectStaticComponent('actionForm'));
  actionForm.hasChanged ? action.payload.displayActionConfirmationDialog() : yield call(handleActionFormClose);
}

export function* handleActionFormClose(): any {
  yield put(toggleComponentDisplayAction('actionForm', false));
}

export function* handleInspectorFormDiscardChanges(action: { payload: InspectorFormDiscardActionPayload }): any {
  const inspectorForm: inspectorFormData = action?.payload?.isGlobalViewInspectorFooter
    ? yield select(selectGlobalForm)
    : yield select(selectCurrentForm);
  inspectorForm.isFormDirty
    ? action.payload.displayInspectorConfirmationDialog()
    : yield call(handleInspectorFormClose, action);
}

export function* handleInspectorFormClose(action: any): any {
  const clientViewUri = action?.payload?.isGlobalViewInspectorFooter ? 'global' : yield select(activeTabSelector);
  yield put(setInspectorFormEdit(false, clientViewUri));
  yield call(onInspectorRelease);
  if (action == true || action?.payload?.hideInspector) {
    if (action?.payload?.isGlobalViewInspectorFooter) {
      yield put(updateComponentAction(clientViewUri, { [VIEW_INSPECTOR]: { isCollapsed: true, isExpanded: false } }));
    } else {
      yield put(updateComponentViewAction(VIEW_INSPECTOR, clientViewUri, { isCollapsed: true, isExpanded: false }));
    }
  }
}

function* watchSaveInspectorForm() {
  yield takeLatest<any>(inspectorFormsAction.SAVE_INSPECTOR_FORM, onInspectorSaveForm);
}

function* watchSaveModalForm() {
  yield takeLatest<any>(modalFormsAction.SAVE_MODAL_FORM, onModalSaveForm);
}

function* watchSaveCreditDetailModalForm() {
  yield takeLatest<any>(creditDetailFormsAction.SAVE_CREDIT_DETAIL_MODAL_FORM, onCreditDetailModalSaveForm);
}

function* watchModalFormDiscardChanges() {
  yield takeLatest<any>(modalFormsAction.MODAL_FORM_DISCARD_CHANGES, handleModalFormDiscardChanges);
}

function* watchModalFormClose() {
  yield takeLatest<any>(modalFormsAction.MODAL_FORM_CLOSE, handleModalFormClose);
}

function* watchCreditDetailFormDiscardChanges() {
  yield takeLatest<any>(
    creditDetailFormsAction.CREDIT_DETAIL_MODAL_FORM_DISCARD_CHANGES,
    handleCreditDetailFormDiscardChanges,
  );
}

function* watchCreditDetailFormClose() {
  yield takeLatest<any>(creditDetailFormsAction.CREDIT_DETAIL_MODAL_FORM_CLOSE, handleCreditDetailFormClose);
}

function* watchActionFormDiscardChanges() {
  yield takeLatest<any>(ActionForm.ACTION_FORM_DISCARD_CHANGES, handleActionFormDiscardChanges);
}

function* watchActionFormClose() {
  yield takeLatest<any>(ActionForm.ACTION_FORM_CLOSE, handleActionFormClose);
}

function* watchInspectorFormDiscardChanges() {
  yield takeLatest<any>(inspectorFormsAction.INSPECTOR_FORM_DISCARD_CHANGES, handleInspectorFormDiscardChanges);
}

function* watchInspectorFormClose() {
  yield takeLatest<any>(inspectorFormsAction.INSPECTOR_FORM_CLOSE, handleInspectorFormClose);
}

export default [
  watchSaveInspectorForm,
  watchSaveModalForm,
  watchModalFormDiscardChanges,
  watchModalFormClose,
  watchCreditDetailFormDiscardChanges,
  watchCreditDetailFormClose,
  watchActionFormDiscardChanges,
  watchActionFormClose,
  watchInspectorFormDiscardChanges,
  watchInspectorFormClose,
  watchSaveCreditDetailModalForm,
];
